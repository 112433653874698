import React, { lazy, Suspense, useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import "react-lazy-load-image-component/src/effects/blur.css";

const Home = lazy(() => import("./Pages/Home/HomePage.jsx"));
const AnalyticsScripts = lazy(() =>
  import("./Components/AnalyticsScripts/AnalyticsScripts.jsx")
);
const ChatBot = lazy(() => import("./Components/Chat/Chat"));
const Error404 = lazy(() => import("./Pages/Error404Page/Error404"));
const About = lazy(() => import("./Pages/AboutNew/About.jsx"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const ViewBlog = lazy(() => import("./Pages/Blogs/ViewBlog"));
const NfcOrder = lazy(() =>
  import("./Pages/CardLandingPage/NFC/BusinessCard.jsx")
);
const ThankYouPage = lazy(() =>
  import("./Pages/ThankYouPage/ThankYouPage.jsx")
);
const NfcCardFinal = lazy(() =>
  import("./Pages/CardLandingPage/NFC/NfcCard/NfcCardFinal.jsx")
);
const ContactSales = lazy(() => import("./Pages/ContactSales/Sales"));
const Privacy = lazy(() => import("./Pages/Privacy/Privacy.jsx"));
const GetDemo = lazy(() => import("./Pages/GetDemo/GetDemo.jsx"));
const PricingV2 = lazy(() => import("./Pages/PricingV2/PricingV2.jsx"));

const DigitalBusinessCard = lazy(() =>
  import("./Pages/LandingPages/DigitalBusinessCard/DigitalBusinessCard.jsx")
);
const VirtualBusinessCard = lazy(() =>
  import("./Pages/LandingPages/VirtualBusinessCard/VirtualBusinessCard.jsx")
);
const BestNfcBusinessCard = lazy(() =>
  import("./Pages/LandingPages/BestNfcBusinessCard/BestNfcBusinessCard.jsx")
);
const DigitalBusinessCardWithQrCode = lazy(() =>
  import(
    "./Pages/LandingPages/DigitalBusinessCardWithQrCode/DigitalBusinessCardWithQrCode.jsx"
  )
);
const ElectronicBusinessCard = lazy(() =>
  import(
    "./Pages/LandingPages/ElectronicBusinessCard/ElectronicBusinessCard.jsx"
  )
);

function App() {
  axios.defaults.baseURL = "https://leaponapi.herokuapp.com";
  //axios.defaults.baseURL = "https://leaponapi-test.herokuapp.com";
  axios.defaults.withCredentials = true;
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnalyticsLoaded(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.getElementById("preloader").style.visibility = "hidden";
  }, []);

  return (
    <Suspense fallback={<div id="preloader"></div>}>
      <ChatBot />
      {isAnalyticsLoaded && <AnalyticsScripts />}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/digital-business-card"
            element={<DigitalBusinessCard />}
          />
          <Route
            path="/best-nfc-business-card"
            element={<BestNfcBusinessCard />}
          />
          <Route
            path="/electronic-business-card"
            element={<ElectronicBusinessCard />}
          />
          <Route
            path="/virtual-business-card"
            element={<VirtualBusinessCard />}
          />
          <Route
            path="/digital-business-card-with-qr-code"
            element={<DigitalBusinessCardWithQrCode />}
          />
          <Route path="/smart-business-card" element={<NfcOrder />} />
          <Route
            path="/business-card"
            element={<Navigate to="/smart-business-card" />}
          />
          <Route path="/create-card" element={<NfcCardFinal />} />
          <Route path="/error404" element={<Error404 />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:blogID" element={<ViewBlog />} />
          <Route path="/contact-sales" element={<ContactSales />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/get-demo" element={<GetDemo />} />
          <Route path="/pricing" element={<PricingV2 />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
